import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(
    protected httpClient: HttpClient
  ) { }

  getProfiles(): Observable<any[]> {
    return this.httpClient.get<any[]>('/mobilaldiabff/admin/perfiles');
  }
}
