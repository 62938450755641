import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class SubnavbarService {

  subMenuForm: FormGroup;
  informationForm: FormGroup;
  products: Array<number>;
  contenidos: Array<number>;
  sitios: Array<number>;
  initialValues: any;

  constructor(
    protected httpClient: HttpClient,
    private formBuilder: FormBuilder
  ) {
    this.subMenuForm = this.formBuilder.group({
      id: null,
      id_scmenu: null,
      name: ['', [Validators.required, Validators.minLength(3)]],
      str_index: [''],
      link: [''],
      color: [''],
      color_borde: [''],
      color_hover: [''],
      id_submenu_type: ['', Validators.required],
      icon: [''],
      image: [''],
      // title: [''],
      description: [''],
      actived: [''],
      visible_top: [''],
      visible_login: [''],
      id_producto: Array(),
      idListContenidos: Array(),
      idSitios:Array()

    });
    this.initialValues = this.subMenuForm.value;
  }

  getSubMenu() {
    return this.httpClient.get('/mobilaldiabff/admin/scsubmenus');
  }

  getSubNavBars(id: any) {
    return this.httpClient.get('/mobilaldiabff/admin/scmenus/' + id + '/scsubmenus');
  }

  getProducts(id: number) {
    return this.httpClient.get('/mobilaldiabff/admin/scsubmenus/' + id + '/products');
  }

  getContenidos(id: number) {
    return this.httpClient.get('/mobilaldiabff/admin/scsubmenus/' + id + '/contenidos');
  }


  getSitios(id: number) {
    return this.httpClient.get('/mobilaldiabff/admin/submenus/' + id + '/sitios');
  }

  store(data: any) {

    this.products = [];
    this.contenidos=[];
    this.sitios=[];

    if (Array.isArray(data.id_producto)) {
      data.id_producto.forEach((value: any, key: any) => {
        this.products.push(value.id_producto);
      });
    }

    if (Array.isArray(data.idListContenidos)) {
      data.idListContenidos.forEach((value: any, key: any) => {
        this.contenidos.push(value.id);
      });
    }

    if (Array.isArray(data.idSitios)) {
      data.idSitios.forEach((value: any, key: any) => {
        this.sitios.push(value.id);
      });
    }
    const body = new HttpParams()
      .set('color', data.color)
      .set('color_hover', data.color_hover)
      .set('color_borde', data.color_borde)
      .set('descripcion', data.description)
      .set('estado', (data.actived) ? '1' : '0')
      .set('icono', '/resource/IMAGES/' + data.icon) // shopping-bag.png
      .set('imagen', '/resource/SECTIONS/' + data.image) // coffee.jpg
      .set('link', data.link)
      .set('nombre', data.name)
      .set('strIndex', data.str_index)
      .set('id_tipo_submenu', data.id_submenu_type.id_tipo_submenu)
      .set('titulo_descripcion', data.title)
      .set('visibleTop', data.visible_top.value)
      .set('visibleOnlyLogin', data.visible_login.value)
      .set('id_scmenu', localStorage.getItem('id_scmenu'))
      .set('id_producto', this.products.join(','))
      .set('idListContenidos', this.contenidos.join(','))
      .set('idSitios', this.sitios.join(','));

    return this.httpClient.post<any>(
      '/mobilaldiabff/admin/scsubmenus',
      body.toString(),
      { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8') }
    );
  }

  edit(data: any) {
    this.subMenuForm.setValue(data);
  }

  update(data: any) {
    
    this.products = [];
    this.contenidos=[];
    this.sitios=[];

    if (Array.isArray(data.id_producto)) {
      data.id_producto.forEach((value: any, key: any) => {
        this.products.push(value.id_producto);
      });
    }

    if (Array.isArray(data.idListContenidos)) {
      data.idListContenidos.forEach((value: any, key: any) => {
        this.contenidos.push(value.id);
      });
    }

    if (Array.isArray(data.idSitios)) {
      data.idSitios.forEach((value: any, key: any) => {
        this.sitios.push(value.id);
      });
    }

    const body = new HttpParams()
      .set('color', data.color)
      .set('color_hover', data.color_hover)
      .set('color_borde', data.color_borde)
      .set('descripcion', data.description)
      .set('estado', (data.actived) ? '1' : '0')
      .set('icono', '/resource/IMAGES/' + data.icon) // shopping-bag.png
      .set('imagen', '/resource/SECTIONS/' + data.image) // coffee.jpg
      .set('link', data.link)
      .set('nombre', data.name)
      .set('strIndex', data.str_index)
      .set('id_tipo_submenu', data.id_submenu_type.id_tipo_submenu)
      .set('titulo_descripcion', data.title)
      .set('visibleTop', data.visible_top.value)
      .set('visibleOnlyLogin', data.visible_login.value)
      .set('id_producto', this.products.join(','))
      .set('listIdContenidos', this.contenidos.join(','))
      .set('idSitios', this.sitios.join(','));


    return this.httpClient.put<any>(
      '/mobilaldiabff/admin/scsubmenus/' + data.id,
      body.toString(),
      { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8') }
    );
  }

  delete(id: number) {
    return this.httpClient.delete('/mobilaldiabff/admin/scsubmenus/' + id);
  }

  getInformationSubMenu(id: number) {
    return this.httpClient.get('/mobilaldiabff/admin/scsubmenus/' + id + '/scinformacionsubmenu');
  }

}
