import { Component, AfterViewInit, ViewChild, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { DialogService } from '../../shared/services/dialog.service';
import { SelectionModel } from '@angular/cdk/collections';
import { NotificationsService } from '../../shared/services/notifications.service';
import { IUsersElement } from '../../shared/interfaces/iUsers-element';
import { JwtService } from '../../shared/services/jwt.service';
import { Location } from '@angular/common';
import { UsersService } from '../../shared/services/users.service';
import { CreateUserComponent } from '../create-user/create-user.component';



@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit {
  loading: boolean;
  dataSource = new MatTableDataSource<IUsersElement>();
  selection = new SelectionModel<IUsersElement>(true, []);
  hidden : boolean;
  editVideoComponent : CreateUserComponent;


  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private activeRouter: ActivatedRoute,
    private location: Location,
    private navigator: Router,
    protected usersService: UsersService,
    private dialog: MatDialog,
    private dialogConfirm: DialogService,
    private notificationService: NotificationsService,
    protected jwtService: JwtService
  ) {
      this.loading = true;
  }

  public displayedColumns: string[] = [
    // 'select',
    'idCustomer',
    'username',
    'zonaEds',
    'acceso',
    'fechaUltimoLogin',
    'estado',
    'actions'
  ];

  ngOnInit() {
    if (!this.jwtService.isTokenExpired) {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.getUsers();
    } else {
      this.notificationService.message(':: Sesión expirada. Por favor, vuelva a iniciar sesión', 'warn');
    }
  }

  getUsers() {
    this.activeRouter.queryParams.subscribe(params => {
    this.hidden = (params.idCustomer) ? true : false;
    this.usersService.getUsers().subscribe((data: any) => {
    this.dataSource.data = data as IUsersElement[];
    }, (error: any) => {
      console.error(error);
    }, () => { this.loading = false; });
      });
  }
  /* Se abre el modal para crear o editar un video */
  openModal(data ? : any) : void {

      this.dialog.open(CreateUserComponent, {
        width: '60%',
        height: '85%',
        autoFocus: true,
        disableClose: true,
        data : data
      }).afterClosed().subscribe((res) => {
        if (res) {
          this.loading = true;
          this.getUsers();
        }
      });
    }


/* Borra un video */
  delete(idCustomer: number) {
    this.dialogConfirm.openConfirmDialog('¿Estás seguro de eliminar este registro?')
    .afterClosed().subscribe(res => {
      if (res) {
        this.usersService.delete(idCustomer).subscribe((data: any) => {
          this.notificationService.message(':: Registro eliminado satisfactoriamente', 'success');
        }, (error) => {
          console.error(error);
        }, () => { this.ngOnInit(); });
      }
    });
  }

    /** Filter rows matches. */
    applyFilter(filterValue: string) {
      this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
      const numSelected = this.selection.selected.length;
      const numRows = this.dataSource.data.length;
      return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
      this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach(row => this.selection.select(row));
    }

    /** The label for the checkbox on the passed row */
    checkboxLabel(row?: IUsersElement): string {
      if (!row) {
        return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
      }
      return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.idCustomer + 1}`;
    }


}
