import { Injectable } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import {CustomURLEncoder} from '../../urlencoder/component';


@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  registerForm: FormGroup;

  constructor(
    protected httpClient: HttpClient,
    private formBuilder: FormBuilder
  ) {
    this.registerForm = this.formBuilder.group({
      id: null,
      name: ['', [Validators.required, Validators.minLength(3)]],
      terms: ['', [Validators.required, Validators.minLength(3)]],
      image: ['', Validators.required],
      str_index: ['', Validators.required],
      color_hover: ['', Validators.required],
      type: ['', [Validators.required]],
      id_item: ['', [Validators.required]],
      nominative: false
    });
  }

  getCategories(id?: number): Observable<any> {
    if (!id) {
      return this.httpClient.get<any[]>('/mobilaldiabff/admin/categoriaproducto');
    } else {
      return this.httpClient.get<any[]>('/mobilaldiabff/admin/categoriaproducto/' + id);
    }
  }

  edit(data: any) {
    this.registerForm.setValue(data);
  }

  store(data: any) {
    const body = new HttpParams({encoder: new CustomURLEncoder()})
      .set('nombre', data.name)
      .set('condiciones_legales', data.terms)
      .set('strindex', data.str_index)
      .set('color_hover', data.color_hover)
      .set('imagen', '/resource/images/' + data.image)
      .set('tipo', data.type.id)
      .set('id_rubro', data.id_item.id_rubro)
      .set('nominativa', data.nominative);

    return this.httpClient.post<any>(
      '/mobilaldiabff/admin/categoriaproducto',
      body.toString(),
      { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8') }
    );
  }

  update(data: any) {
    const body = new HttpParams({encoder: new CustomURLEncoder()})
      .set('nombre', data.name)
      .set('condiciones_legales', data.terms)
      .set('strindex', data.str_index)
      .set('color_hover', data.color_hover)
      .set('imagen', '/resource/images/' + data.image)
      .set('tipo', data.type.id)
      .set('id_rubro', data.id_item.id_rubro)
      .set('nominativa', data.nominative);

    return this.httpClient.put<any>(
      '/mobilaldiabff/admin/categoriaproducto/' + data.id,
      body.toString(),
      { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8') }
    );
  }

  delete(id: number) {
    return this.httpClient.delete('/mobilaldiabff/admin/categoriaproducto/' + id);
  }
}
