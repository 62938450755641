import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {CustomURLEncoder} from '../../urlencoder/component';

@Injectable({
  providedIn: 'root'
})
export class ViewService {

  registerForm: FormGroup;

  constructor(
    protected httpClient: HttpClient,
    private formBuilder: FormBuilder
  ) {
    this.registerForm = this.formBuilder.group({
      id_view: null,
      name: ['', [Validators.required, Validators.minLength(3)]],
      content: ['', [Validators.required, Validators.minLength(3)]],
      activated: false
    });
  }

  getViews(name?: string) {
    if (!name) {
      return this.httpClient.get('/mobilaldiabff/admin/views');
    } else {
      return this.httpClient.get('/mobilaldiabff/admin/views/getByName/' + name);
    }
  }

  // getByName(name: string) {
  //   return this.httpClient.get('/mobilaldiabff/admin/views/getByName/' + name);
  // }

  store(data: any) {
    const body = new HttpParams({encoder: new CustomURLEncoder()})
      .set('content', data.content)
      .set('estado', (data.activated) ? '1' : '0');

    return this.httpClient.post<any>(
      '/mobilaldiabff/admin/views',
      body.toString(),
      { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8') }
    );
  }

  edit(data: any) {
    this.registerForm.setValue(data);
  }

  update(data: any) {
    const body = new HttpParams({encoder: new CustomURLEncoder()})
      .set('id_view', data.id_view)
      .set('name', data.name)
      .set('content',  data.content)
      .set('estado', (data.activated) ? '1' : '0');

    return this.httpClient.post<any>(
      '/mobilaldiabff/admin/views',
      body.toString(),
      { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8') }
    );
  }

  delete(id: number) {
    return this.httpClient.delete('/mobilaldiabff/admin/views/' + id);
  }

  activate(id: number) {
    return this.httpClient.put('/mobilaldiabff/admin/views/' + id + '/activate',
    { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8') });
  }
}
