import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import * as _moment from 'moment';
import 'moment/locale/es';
import { ISitiosElement } from '../interfaces/isitios-element';

import {CustomURLEncoder} from '../../urlencoder/component';

const moment = _moment;
moment.locale('es');

const PATH: string = "/admin/sitios";

@Injectable({
	providedIn: 'root'
})
export class SitiosService {

	constructor(
		protected httpClient: HttpClient
	) { }

	getSites(): Observable<ISitiosElement[]> {
		return this.httpClient.get<ISitiosElement[]>('/mobilaldiabff' + PATH);
	}

	store(data: any) {
		const initDate = moment(data.fechaInicio);
		const endDate = moment(data.fechaFin);

		const body = new HttpParams({encoder: new CustomURLEncoder()})
			.set('nombre', data.nombre)
			.set('direccion', data.direccion)
			.set('telefono', data.telefono)
			.set('web', data.web)
			.set('especialidad', data.especialidad)
			.set('imagen', '/resource/images/' + data.imagen)
			.set('idRegion', data.idRegion)
			.set('descripcionPromocion', data.descripcionPromocion)
			.set('fechaInicio', initDate.format('YYYY-MM-DD HH:mm:ss'))
			.set('fechaFin', endDate.format('YYYY-MM-DD HH:mm:ss'))
			.set('orden', data.orden)
			.set('activo', data.activo);

		return this.httpClient.post<any>(
			'/mobilaldiabff' + PATH,
			body.toString(),
			{ headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8') }
		);
	}

	update(data: any): Observable<ISitiosElement[]> {
		const initDate = moment(data.fechaInicio);
		const endDate = moment(data.fechaFin);

		const body = new HttpParams({encoder: new CustomURLEncoder()})
			.set('nombre', data.nombre)
			.set('direccion', data.direccion)
			.set('telefono', data.telefono)
			.set('web', data.web)
			.set('especialidad', data.especialidad)
			.set('imagen', '/resource/images/' + data.imagen)
			.set('idRegion', data.idRegion)
			.set('descripcionPromocion', data.descripcionPromocion)
			.set('fechaInicio', initDate.format('YYYY-MM-DD HH:mm:ss'))
			.set('fechaFin', endDate.format('YYYY-MM-DD HH:mm:ss'))
			.set('orden', data.orden)
			.set('activo', data.activo);

		return this.httpClient.put<any>(
			'/mobilaldiabff' + PATH + '/' + data.$key,
			body.toString(),
			{ headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8') }
		);
	}

	delete(id:number) {
		return this.httpClient.delete('/mobilaldiabff' + PATH + "/" + id);
	}
}
