import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import * as _moment from 'moment';
import 'moment/locale/es';
import { IUsersElement } from '../interfaces/iusers-element';

import {CustomURLEncoder} from '../../urlencoder/component';

const moment = _moment;
moment.locale('es');

const PATH: string = "/admin/customers";

@Injectable({
	providedIn: 'root'
})
export class UsersService {
	private estado:string;
	private parejaEds:string;
	private nombreParejaEds:string;
	private emailParejaEds:string;
	constructor(
		protected httpClient: HttpClient
	) { }

	getUsers(): Observable<IUsersElement[]> {
		return this.httpClient.get<IUsersElement[]>('/mobilaldiabff' + PATH);
	}

	store(data: any) {
		const fechaIngresoCopec= moment(data.fechaIngresoCopec);
		const fechaNacimiento = moment(data.fechaNacimiento);
		const fechaNacimientoParejaEds = moment(data.fechaNacimientoParejaEds);
		if(data.estado){ 
			this.estado="1"; 
		}else{ 
			this.estado="0";
		 };
		if(data.parejaEds==1){ 
			this.parejaEds="true";
			this.nombreParejaEds=data.nombreParejaEds;
			this.emailParejaEds=data.emailParejaEds;
		 }else{ 
			 this.parejaEds="false";
			 this.nombreParejaEds="Sin Nombre";
			 this.emailParejaEds="Sin Correo";
			
			};

		const body = new HttpParams({encoder: new CustomURLEncoder()})
			.set('nombre', data.nombre)
			.set('username', data.username)
			.set('direccion', data.direccion)
			.set('comuna', data.comuna)
			.set('telefono', data.telefono)
			.set('rut', data.rut)
			.set('nombreParejaEds', this.nombreParejaEds)
			.set('parejaEds', this.parejaEds)
			.set('emailParejaEds', this.emailParejaEds)
			.set('imagen', data.imagen)
			.set('idRegion', data.idRegion)
			.set('zonaEds', data.zonaEds)
			.set('fechaIngresoCopec', fechaIngresoCopec.format('YYYY-MM-DD HH:mm:ss'))
			.set('fechaNacimiento', fechaNacimiento.format('YYYY-MM-DD HH:mm:ss'))
			.set('fechaNacimientoParejaEds', fechaNacimientoParejaEds.format('YYYY-MM-DD HH:mm:ss'))
			.set('orden', data.orden)
			.set('estado', this.estado)
			.set('tipoLogin', data.tipoLogin)
			.set('password', data.password)
			.set('acceso', data.acceso);

		return this.httpClient.post<any>(
			'/mobilaldiabff' + PATH,
			body.toString(),
			{ headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8') }
		);
	}

	update(data: any): Observable<IUsersElement[]> {
		const fechaIngresoCopec= moment(data.fechaIngresoCopec);
		const fechaNacimiento = moment(data.fechaNacimiento);
		const fechaNacimientoParejaEds = moment(data.fechaNacimientoParejaEds);
		if(data.estado){ 
			this.estado="1"; 
		}else{ 
			this.estado="0";
		 };
		if(data.parejaEds==1){ 
			this.parejaEds="true";
			this.nombreParejaEds=data.nombreParejaEds;
			this.emailParejaEds=data.emailParejaEds;
		 }else{ 
			 this.parejaEds="false";
			 this.nombreParejaEds="Sin Nombre";
			 this.emailParejaEds="Sin Correo";
			
			};

		const body = new HttpParams({encoder: new CustomURLEncoder()})
			.set('nombre', data.nombre)
			.set('username', data.username)
			.set('direccion', data.direccion)
			.set('comuna', data.comuna)
			.set('telefono', data.telefono)
			.set('rut', data.rut)
			.set('nombreParejaEds', this.nombreParejaEds)
			.set('parejaEds', this.parejaEds)
			.set('emailParejaEds', this.emailParejaEds)
			.set('imagen', data.imagen)
			.set('idRegion', data.idRegion)
			.set('zonaEds', data.zonaEds)
			.set('fechaIngresoCopec', fechaIngresoCopec.format('YYYY-MM-DD HH:mm:ss'))
			.set('fechaNacimiento', fechaNacimiento.format('YYYY-MM-DD HH:mm:ss'))
			.set('fechaNacimientoParejaEds', fechaNacimientoParejaEds.format('YYYY-MM-DD HH:mm:ss'))
			.set('orden', data.orden)
			.set('estado', this.estado)
			.set('tipoLogin', data.tipoLogin)
			.set('password', data.password)
			.set('acceso', data.acceso);
			

		return this.httpClient.put<any>(
			'/mobilaldiabff' + PATH + '/' + data.idCustomer,
			body.toString(),
			{ headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8') }
		);
	}

	delete(id:number) {
		return this.httpClient.delete('/mobilaldiabff' + PATH + "/" + id);
	}
}
