import { Injectable } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class InformationSubmenuService {

  informationForm: FormGroup;

  constructor(
    protected httpClient: HttpClient,
    private formBuilder: FormBuilder
  ) {
    this.informationForm = this.formBuilder.group({
      id_scsubmenu_informacion: null,
      id_submenu: null,
      nombre: ['', Validators.required, Validators.minLength(3)],
      tipo: ['', Validators.required],
      imagen: ['', Validators.required],
      titulo: ['', Validators.required],
      subtitulo: ['', Validators.required],
      descripcion: ['', Validators.required],
      link: ['', Validators.required],
      texto_link: ['', Validators.required],
      json_condiciones: ['', Validators.required],
      fecha_creacion: [''],
      fecha_modificacion: [''],
      imagen_logo: [''],
      condicioneslista: [''],
      estado: [true]
    });
  }

  getInfoSubMenu(id: number) {
    return this.httpClient.get('/mobilaldiabff/admin/scinformacionsubmenu/get/' + id);
  }

  edit(data: any) {
    console.log(data);
    this.informationForm.setValue(data);
  }

}
