import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { CustomURLEncoder } from 'src/app/urlencoder/component';

@Injectable({
  providedIn: 'root'
})
export class ContenidosService {
  // contenidoForm : FormGroup;
  private urlEndPoint: string ='/mobilaldiabff/admin/contenidos';
  /*private httpHeaders = new HttpHeaders({'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'});*/
  videos: Array<number>;
  noticias: Array<number>;

  constructor(
    protected httpClient: HttpClient,
  //  private formBuilder: FormBuilder
  ){
  }

/* Obtener todos los contenidos o contenidos segun id del submenú */
  getContenidos(id?: number, submenu: boolean = false) {
    if (submenu) {
      return this.httpClient.get('/mobilaldiabff/admin/scsubmenus/' + id + '/contenidos');
    } else {
      if (!id) {
        return this.httpClient.get(this.urlEndPoint);
      } else {
        return this.httpClient.get(this.urlEndPoint + '/' + id);
      }
    }

  }

  getVideos(id: number) {
    return this.httpClient.get(this.urlEndPoint + '/' + id + '/videos');
  }

  getNoticias(id: number) {
    return this.httpClient.get(this.urlEndPoint +'/' + id + '/noticias');
  }




  create(data: any) {
    this.videos = [];
    this.noticias=[];

/* Validamos si hay videos para pasar de ser así por cada video rescatamos el id
 y lo guardamos en el array Number 'videos' */
    if (Array.isArray(data.listVideos)) {
      data.listVideos.forEach((value: any, key: any) => {
        this.videos.push(value.id);
      });
    }
    /* Validamos si hay noticias para pasar de ser así por cada noticia rescatamos el id
     y lo guardamos en el array Number 'videos' */
    if (Array.isArray(data.listNoticias)) {
      data.listNoticias.forEach((value: any, key: any) => {
        this.noticias.push(value.id);
      });
    }

/* Creamos nuestros parametros que le enviaremos al back */
    const body = new HttpParams({encoder: new CustomURLEncoder()})
      .set('nombre', data.nombre)
      .set('orden', data.orden)
      .set('estado', (data.estado) ? '1' : '0')
      .set('descripcion', data.descripcion)
      .set('listIdVideos', this.videos.join(','))
      .set('listIdNoticias', this.noticias.join(','));

    return this.httpClient.post<any>(
      this.urlEndPoint,
      body.toString(),
      { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8') }
    );
  }


  update(data: any) {
    this.videos = [];
    this.noticias=[];

/* Validamos si hay videos para pasar de ser así por cada video rescatamos el id
 y lo guardamos en el array Number 'videos' */
    if (Array.isArray(data.listVideos)) {
      data.listVideos.forEach((value: any, key: any) => {
        this.videos.push(value.id);
      });
    }
    /* Validamos si hay noticias para pasar de ser así por cada noticia rescatamos el id
     y lo guardamos en el array Number 'videos' */
    if (Array.isArray(data.listNoticias)) {
      data.listNoticias.forEach((value: any, key: any) => {
        this.noticias.push(value.id);
      });
    }
    const body = new HttpParams({encoder: new CustomURLEncoder()})
      .set('nombre', data.nombre)
      .set('descripcion', data.descripcion)
      .set('orden', data.orden)
      .set('estado', (data.estado) ? '1' : '0')
      .set('listIdVideos', this.videos.join(','))
      .set('listIdNoticias', this.noticias.join(','));
    return this.httpClient.put<any>(
      this.urlEndPoint + '/' + data.id,
      body.toString(),
      { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8') }
    );
  }


  delete(id: number) {
    return this.httpClient.delete(this.urlEndPoint + '/' + id);
  }
}
