import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IIconElement } from 'src/app/shared/interfaces/iicon-element';
import {CustomURLEncoder} from '../../urlencoder/component';

@Injectable({
  providedIn: 'root'
})
export class TagsService {

  registerForm: FormGroup;

  constructor(
    protected httpClient: HttpClient,
    private formBuilder: FormBuilder
  ) {
    this.registerForm = this.formBuilder.group({
      id_tag_producto: null,
      nombre: ['', [Validators.required, Validators.minLength(3)]],
      descripcion: ['', [Validators.required, Validators.minLength(3)]],
      icono: ['', [Validators.required]],
      order: ['', Validators.required],
      estado: [true]
    });
  }

  getTags() {
    return this.httpClient.get('/mobilaldiabff/admin/tags_productos');
  }

  edit(data: any) {
    this.registerForm.setValue(data);
  }

  store(data: any) {
    const body = new HttpParams({encoder: new CustomURLEncoder()})
      .set('nombre', data.nombre)
      .set('descripcion', data.descripcion)
      .set('icono', data.icono.icon)
      .set('orden', data.order)
      .set('estado', data.estado);

    return this.httpClient.post<IIconElement>(
      '/mobilaldiabff/admin/tags_productos',
      body.toString(),
      { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8') }
    );
  }

  update(data: any) {
    const body = new HttpParams({encoder: new CustomURLEncoder()})
      .set('nombre', data.nombre)
      .set('descripcion', data.descripcion)
      .set('icono', data.icono.icon)
      .set('orden', data.order)
      .set('estado', data.estado);

    return this.httpClient.put<IIconElement>(
      '/mobilaldiabff/admin/tags_productos/' + data.id_tag_producto,
      body.toString(),
      { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8') }
    );
  }

  delete(id: number) {
    return this.httpClient.delete('/mobilaldiabff/admin/tags_productos/' + id);
  }

  getIcons() {
    return this.httpClient.get<IIconElement[]>('../../assets/data/icons.json');
  }
}
