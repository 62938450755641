import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import * as _moment from 'moment';
import 'moment/locale/es';
import { IBannerElement } from '../interfaces/ibanner-element';

import {CustomURLEncoder} from '../../urlencoder/component';

const moment = _moment;
moment.locale('es');

@Injectable({
  providedIn: 'root'
})
export class BannerService {

  registerForm: FormGroup;


  constructor(
    protected httpClient: HttpClient,
    private formBuilder: FormBuilder
  ) {
    this.registerForm = this.formBuilder.group({
      $key: null,
      name: ['', [Validators.required, Validators.minLength(3)]],
      route: null,
      url: null,
      tipo: 1,
      order: ['', Validators.required],
      date_init: moment(),
      date_end: moment(),
      status: [false],
      blank: [false],
      responsive: [false],
      html: ''
    });
  }

  getBanners(): Observable<IBannerElement[]> {
    return this.httpClient.get<IBannerElement[]>('/mobilaldiabff/admin/banners');
  }

  show(id: any) {
    return this.httpClient.get('/mobilaldiabff/admin/banners/' + id);
  }

  edit(data: any) {
    this.registerForm.setValue(data);
  }

  store(data: any) {
    const initDate = moment(data.date_init);
    const endDate = moment(data.date_end);

    const body = new HttpParams({encoder: new CustomURLEncoder()})
      .set('blank', (data.blank) ? '1' : '0')
      .set('fecha_fin', endDate.format('YYYY-MM-DD HH:mm:ss'))
      .set('fecha_inicio', initDate.format('YYYY-MM-DD HH:mm:ss'))
      .set('orden', data.order)
      .set('ruta', '/resource/banner/' + data.route)
      .set('url', data.url)
      .set('tipo', data.tipo)
      .set('estado', (data.status) ? '1' : '0')
      .set('responsive', (data.responsive) ? '1' : '0')
      .set('nombre', data.name)
      .set('html', data.html);

    return this.httpClient.post<any>(
      '/mobilaldiabff/admin/banners',
      body.toString(),
      { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8') }
    );
  }

  update(data: any): Observable<IBannerElement[]> {
    const initDate = moment(data.date_init);
    const endDate = moment(data.date_end);

    const body = new HttpParams({encoder: new CustomURLEncoder()})
      .set('blank', (data.blank) ? '1' : '0')
      .set('fecha_fin', endDate.format('YYYY-MM-DD HH:mm:ss'))
      .set('fecha_inicio', initDate.format('YYYY-MM-DD HH:mm:ss'))
      .set('orden', data.order)
      .set('ruta', '/resource/banner/' + data.route)
      .set('url', data.url)
      .set('tipo', data.tipo)
      .set('estado', (data.status) ? '1' : '0')
      .set('responsive', (data.responsive) ? '1' : '0')
      .set('nombre', data.name)
      .set('html', data.html);

    return this.httpClient.put<IBannerElement[]>(
      '/mobilaldiabff/admin/banners/' + data.$key,
      body.toString(),
      { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8') });
  }

  delete(id: number) {
    return this.httpClient.delete('/mobilaldiabff/admin/banners/' + id);
  }
}
