import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import {CustomURLEncoder} from '../../urlencoder/component';

@Injectable({
  providedIn: 'root'
})
export class InformationService {

  constructor(
    protected httpClient: HttpClient,
    private formBuilder: FormBuilder
  ) { }

  informationForm: FormGroup = this.formBuilder.group({
    id: null,
    name: ['', [Validators.required, Validators.minLength(3)]],
    type: ['', Validators.required],
    content: ['', [Validators.required, Validators.minLength(10)]],
    status: [''],
    id_submenu: ['', Validators.required]
  });

  getInformations() {
    return this.httpClient.get('/mobilaldiabff/admin/informations');
  }

  edit(data: any) {
    this.informationForm.setValue(data);
  }

  store(data: any) {
    console.log(data);
    const body = new HttpParams({encoder: new CustomURLEncoder()})
      .set('id_submenu', data.id_submenu.id)
      .set('nombre', data.name)
      .set('tipo', data.type.id)
      .set('estado', (data.status) ? '1' : '0')
      .set('html', data.content);

    return this.httpClient.post<any>(
      '/mobilaldiabff/admin/informations',
      body.toString(),
      { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8') }
    );
  }

  update(data: any) {
    console.log(data);
    const body = new HttpParams({encoder: new CustomURLEncoder()})
      .set('id_submenu', data.id_submenu.id)
      .set('nombre', data.name)
      .set('tipo', data.type.id)
      .set('estado', (data.status) ? '1' : '0')
      .set('html', data.content);

    return this.httpClient.put<any>(
      '/mobilaldiabff/admin/informations/' + data.id,
      body.toString(),
      { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8') });
  }

  delete(id: number) {
    return this.httpClient.delete('/mobilaldiabff/admin/informations/' + id);
  }
}
