import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import {CustomURLEncoder} from '../../urlencoder/component';

@Injectable({
  providedIn: 'root'
})
export class NoticiasService {
  initialValues: any;
  tags: Array<number>;
  recursos: Array<number>;
  private urlEndPoint: string ='/mobilaldiabff/admin/noticias';
  /* private httpHeaders = new HttpHeaders({'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'}); */

  constructor(
    protected httpClient: HttpClient
  ){}

/* Obtener todos las noticias o noticias según id del contenido */
  getNoticias(id?: number, contenido: boolean = false) {
    if (contenido) {
      return this.httpClient.get('/mobilaldiabff/admin/contenidos/'+id+'/noticias');
    } else {
      if (!id) {
        return this.httpClient.get(this.urlEndPoint);
      } else {
        return this.httpClient.get(this.urlEndPoint + '/' + id);
      }
    }

  }


  create(data: any) {
    this.tags =  [];
    this.recursos =  [];

    if (Array.isArray(data.tags)) {
      data.tags.forEach((value: any, key: any) => {
        this.tags.push(value.id_tag_producto);
      });
	}

  if (Array.isArray(data.recursos)) {
    data.recursos.forEach((value: any, key: any) => {
      this.recursos.push(value.id_resource);
    });
}

    const body = new HttpParams({encoder: new CustomURLEncoder()})
      .set('titulo', data.titulo)
      .set('subtitulo', data.subtitulo)
      .set('cuerpo', data.cuerpo)
      .set('orden', data.orden)
      .set('pie', data.pie)
      .set('tipo', (data.tipo) ? '1' : '0')
      .set('estado', (data.estado) ? '1' : '0')
      .set('id_tag', this.tags.join(','))
      .set('id_resource', this.recursos.join(','))
      .set('rutaImagenNoticia', data.rutaImagenNoticia )
      .set('rutaRecursoNoticia', data.rutaRecursoNoticia )
      .set('rutaMiniatura', data.rutaMiniatura);

    return this.httpClient.post<any>(
      this.urlEndPoint,
      body.toString(),
      { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8') }
    );
  }


  update(data: any) {
    this.tags =  [];
    this.recursos =  [];

    if (Array.isArray(data.tags)) {
      data.tags.forEach((value: any, key: any) => {
        this.tags.push(value.id_tag_producto);
      });
	}

  if (Array.isArray(data.recursos)) {
    data.recursos.forEach((value: any, key: any) => {
      this.recursos.push(value.id_resource);
    });
}

    const body = new HttpParams({encoder: new CustomURLEncoder()})
      .set('titulo', data.titulo)
      .set('subtitulo', data.subtitulo)
      .set('cuerpo', data.cuerpo)
      .set('pie', data.pie)
      .set('orden', data.orden)
      .set('tipo', (data.tipo) ? '1' : '0')
      .set('estado', (data.estado) ? '1' : '0')
      .set('id_tag', this.tags.join(','))
      .set('id_resource', this.recursos.join(','))
      .set('rutaImagenNoticia', data.rutaImagenNoticia )
      .set('rutaRecursoNoticia', data.rutaRecursoNoticia )    
      .set('rutaMiniatura', data.rutaMiniatura);

    return this.httpClient.put<any>(
      this.urlEndPoint + '/' + data.id,
      body.toString(),
      { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8') }
    );
  }


  delete(id: number) {
    return this.httpClient.delete(this.urlEndPoint + '/' + id);
  }
}
