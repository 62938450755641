import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
declare var $: any;
import * as AdminLte from 'admin-lte';

@Component({
  selector: 'app-aside',
  templateUrl: './aside.component.html',
  styleUrls: ['./aside.component.scss']
})
export class AsideComponent implements OnInit {

  @Input() scotiaID: string;
  @Input() role: string;
  public isAdmin: boolean;

  constructor(
    private router: Router
  ) { }

  ngOnInit() {
    /* Corrige error con el sidebar al no cargar bien depsues del logín */
    $('[data-widget="treeview"]').Treeview('init');

    this.isAdmin = (this.role == 'ROLE_ADMIN') ? true : false;
  }

  getViews() {
    this.router.navigate(['views']).then( (e) => {
      if (e) {
        console.log('Navigation is successful!');
      } else {
        console.log('Navigation has failed!');
      }
    });
    return false;
  }

  getNavBars() {
    this.router.navigate(['navbars']);
    return false;
  }

  getContenidos() {
    this.router.navigate(['contenidos']);
    return false;
  }

  getNoticias() {
    this.router.navigate(['noticias']);
    return false;
  }

  getVideos() {
    this.router.navigate(['videos']);
    return false;
  }

  getEmails(){
    this.router.navigate(['emails']);
    return false;
    }

  getNoticiasResources(){
      this.router.navigate(['noticiasresources']);
      return false;
    }
  getSitios(){
  this.router.navigate(['sitios']);
  return false;
  }

  getVotaciones(){
  this.router.navigate(['votaciones']);
  return false;
  }


  getBanners() {
    this.router.navigate(['banners']);
    return false;
  }

  getInformations() {
    this.router.navigate(['informations']);
    return false;
  }

  getDetailsFormat() {
    this.router.navigate(['detailsformat']);
    return false;
  }

  getLikes() {
    this.router.navigate(['likes']);
    return false;
  }

  getProducts() {
    this.router.navigate(['products']);
    return false;
  }

  getTags() {
    this.router.navigate(['tags']);
    return false;
  }

  getCategories() {
    this.router.navigate(['categories']);
    return false;
  }

  getItems() {
    this.router.navigate(['items']);
    return false;
  }

  getUsers() {
    this.router.navigate(['users']);
    return false;
  }

  getTickets() {
    this.router.navigate(['tickets']);
    return false;
  }
  
  getHistory_ticket() {
    this.router.navigate(['history_ticket']);
    return false;
  }
  getEmpresas() {
    this.router.navigate(['companies']);
    return false;
  }
  getPanelcategories() {
    this.router.navigate(['panelcategories']);
    return false;
  }

  getMonitor() {
    this.router.navigate(['monitor']);
    return false;
  }

  getAnalytics() {
    this.router.navigate(['analytics']);
    return false;
  }

  getMarcas() {
    this.router.navigate(['marcas']);
    return false;
  }

  getRifas() {
    this.router.navigate(['rifas']);
    return false;
  }

  getPopups() {
    this.router.navigate(['popups']);
    return false;
  }

  getRrss() {
    this.router.navigate(['rrss']);
    return false;
  }
  getBannerscms() {
    this.router.navigate(['cmsbanners']);
    return false;
  }



}
