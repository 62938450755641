import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CodemirrorModule } from '@ctrl/ngx-codemirror';
import { NgSelectModule } from '@ng-select/ng-select';
import {HttpClientModule} from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginModule } from './login/login.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ViewsComponent } from './views/views.component';
import { FilterByPipe } from './shared/pipes/filterby';
import { ValueArrayPipe } from './shared/pipes/valuearray';
import { NormalizePipe } from './shared/pipes/normalize';
import { SumarrayPipe } from './shared/pipes/sumarray';
import { FormatoZonaEdsPipe } from './shared/pipes/formatozonaeds.pipe';
import { CategoriaRecurso } from './shared/pipes/categoriarecurso.pipe';
import {
  MatCardModule,
  MatProgressBarModule,
  MatInputModule,
  MatButtonModule,
  MatProgressSpinnerModule,
  MatTableModule,
  MatCheckboxModule,
  MatIconModule,
  MatDialogModule,
  MatSnackBarModule,
  MatPaginatorModule,
  MatMenuModule,
  MatDividerModule,
  MatGridListModule,
  MatTooltipModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatFormFieldModule,
  MatChipsModule,
  MatSelectModule,
  MatRadioModule,
  MatSlideToggleModule,
  MatSortModule,
} from '@angular/material';

import { MatMomentDateModule, MomentDateAdapter } from '@angular/material-moment-adapter';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ModalComponent } from './modal/modal.component';
import { CreateViewComponent } from './views/create-view/create-view.component';
import { MatToolbarModule, MatSidenavModule, MatListModule } from '@angular/material';
import { ConfirmComponent } from './shared/dialog/confirm/confirm.component';
import { MenuComponent } from './menu/menu/menu.component';
import { BannerListComponent } from './banners/banner-list/banner-list.component';
import { BannerComponent } from './banners/banner/banner.component';
import { PopupListComponent } from './popups/popup-list/popup-list.component';
import { PopupComponent } from './popups/popup/popup.component';
import { ResourceListComponent } from './resources/resource-list/resource-list.component';
import { SubmenuComponent } from './menu/submenu/submenu.component';
import { CreateMenuComponent } from './menu/create-menu/create-menu.component';
import { CreateSubmenuComponent } from './menu/create-submenu/create-submenu.component';
import { InformationListComponent } from './information/information-list/information-list.component';
import { CreateInformationComponent } from './information/create-information/create-information.component';
import { RifaListComponent } from './rifas/rifa-list/rifa-list.component';
import { CreateRifaComponent } from './rifas/create-rifa/create-rifa.component';
import { DetailFormatListComponent } from './detail-format/detail-format-list/detail-format-list.component';
import { CreateDetailFormatComponent } from './detail-format/create-detail-format/create-detail-format.component';
import { BannerService } from './shared/services/banner.service';
import { ProductListComponent } from './products/product-list/product-list.component';
import { CreateProductComponent } from './products/create-product/create-product.component';
import { CompanyListComponent } from './companies/company-list/company-list.component';
import { CreateCompanyComponent } from './companies/create-company/create-company.component';
import { LikeListComponent } from './like/like-list/like-list.component';
import { CreateLikeComponent } from './like/create-like/create-like.component';
import { InformationSubmenuComponent } from './information/information-submenu/information-submenu.component';
import { CategoryListComponent } from './categories/category-list/category-list.component';
import { PanelcategoryListComponent } from './panelcategories/panelcategory-list/panelcategory-list.component';
import { MarcaListComponent } from './marcas/marca-list/marca-list.component';
import { TagListComponent } from './tags/tag-list/tag-list.component';
import { CreateTagComponent } from './tags/create-tag/create-tag.component';
import { UserListComponent } from './users/user-list/user-list.component';
import { CreateUserComponent } from './users/create-user/create-user.component';
import { TicketListComponent } from './tickets/ticket-list/ticket-list.component';
import { CreateTicketComponent } from './tickets/create-ticket/create-ticket.component';
import { AnalyticListComponent } from './analytics/analytic-list/analytic-list.component';
import { RrssComponent } from './analytics/rrss/rrss.component';
import { BannercmsListComponent } from './cmsbanners/banner-list/banner-list.component';
// import { CKEditorModule } from 'ngx-ckeditor';
import { LayoutFrontComponent } from './layout-front/layout-front.component';
import { LayoutModule } from '@angular/cdk/layout';
import { DashboardFrontComponent } from './dashboard-front/dashboard-front.component';
import { CreateCategoryComponent } from './categories/create-category/create-category.component';
import { CreatePanelcategoryComponent } from './panelcategories/create-panelcategory/create-panelcategory.component';
import { CreateMarcaComponent } from './marcas/create-marca/create-marca.component';
import { CKEditorModule } from 'ng2-ckeditor';
import { CloseComponent } from './shared/controls/buttons/close/close.component';
import { FormToolbarComponent } from './shared/controls/form-toolbar/form-toolbar.component';
import { SendResetComponent } from './shared/controls/buttons/send-reset/send-reset.component';
import { FilterComponent } from './shared/controls/filter/filter.component';
import { ItemListComponent } from './items/item-list/item-list.component';
import { CreateItemComponent } from './items/create-item/create-item.component';
import { ImagesComponent } from './shared/controls/images/images.component';
import { SafePipe } from './shared/pipes/safe.pipe';
import { ProgressBarComponent } from './shared/controls/progress-bar/progress-bar.component';
// Nueva sección contenidos
import { ContenidosComponent } from './contenidos/contenidos/contenidos.component';
import { CreateContenidosComponent } from './contenidos/create-contenidos/create-contenidos.component';
import { NoticiasComponent } from './noticias/noticias/noticias.component';
import { CreateNoticiasComponent } from './noticias/create-noticias/create-noticias.component';
import { VideosComponent } from './videos/videos/videos.component';
import { CreateVideosComponent } from './videos/create-videos/create-videos.component';
import { SitiosComponent } from './sitios/sitios/sitios.component';
import { SitiosListComponent } from './sitios/sitios-list/sitios-list.component';
import { VotacionesComponent } from './votaciones/votaciones/votaciones.component';
import { VotacionesListComponent } from './votaciones/votaciones-list/votaciones-list.component';
import { EmailsComponent } from './emails/emails/emails.component';
import { EmailsListComponent } from './emails/emails-list/emails-list.component';
import { ResourceInputComponent } from './shared/controls/resource-input/resource-input.component';
import { NoticiasResourcesListComponent } from './noticiasresources/noticiasresources-list/noticiasresources-list.component';
import { NoticiasResourcesComponent } from './noticiasresources/noticiasresources/noticiasresources.component';
@NgModule({
  declarations: [
    CategoriaRecurso,
    FormatoZonaEdsPipe,
    FilterByPipe,
    ValueArrayPipe,
    SumarrayPipe,
    NormalizePipe,
    AppComponent,
    ViewsComponent,
    CreateViewComponent,
    ConfirmComponent,
    MenuComponent,
    SubmenuComponent,
    BannerListComponent,
    BannerComponent,
    PopupListComponent,
    PopupComponent,
    ResourceListComponent,
    CreateMenuComponent,
    CreateSubmenuComponent,
    InformationListComponent,
    CreateInformationComponent,
    RifaListComponent,
    CreateRifaComponent,
    DetailFormatListComponent,
    CreateDetailFormatComponent,
    ModalComponent,
    ProductListComponent,
    CreateProductComponent,
    CompanyListComponent,
    CreateCompanyComponent,
    LikeListComponent,
    CreateLikeComponent,
    InformationSubmenuComponent,
    CategoryListComponent,
    PanelcategoryListComponent,
    MarcaListComponent,
    TagListComponent,
    CreateTagComponent,
    UserListComponent,
    CreateUserComponent,
    TicketListComponent,
    CreateTicketComponent,
    AnalyticListComponent,
    RrssComponent,
    BannercmsListComponent,
    LayoutFrontComponent,
    DashboardFrontComponent,
    CreateCategoryComponent,
    CreatePanelcategoryComponent,
    CreateMarcaComponent,
    CloseComponent,
    FormToolbarComponent,
    SendResetComponent,
    FilterComponent,
    ItemListComponent,
    CreateItemComponent,
    ImagesComponent,
    SafePipe,
    ProgressBarComponent,
    ContenidosComponent,
    CreateContenidosComponent,
    NoticiasComponent,
    CreateNoticiasComponent,
    VideosComponent,
    CreateVideosComponent,
    SitiosComponent,
    SitiosListComponent,
    VotacionesComponent,
    VotacionesListComponent,
    EmailsComponent,
    EmailsListComponent,
    ResourceInputComponent,
    NoticiasResourcesListComponent,
    NoticiasResourcesComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    LoginModule,
    DashboardModule,
    FormsModule,
    MatCardModule,
    MatProgressBarModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatInputModule,
    MatCheckboxModule,
    MatIconModule,
    MatDialogModule,
    MatToolbarModule,
    MatSnackBarModule,
    MatPaginatorModule,
    MatMenuModule,
    MatDividerModule,
    MatGridListModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatChipsModule,
    MatSelectModule,
    MatRadioModule,
    MatMomentDateModule,
    NgSelectModule,
    MatSlideToggleModule,
    MatSortModule,
    CKEditorModule,
    LayoutModule,
    MatSidenavModule,
    MatListModule,
    HttpClientModule,
    CodemirrorModule
  ],
  // exports: [CKEditorModule],
  providers: [BannerService],
  bootstrap: [AppComponent],
  entryComponents: [
    CreateViewComponent,
    ConfirmComponent,
    BannerComponent,
    PopupComponent,
    ResourceListComponent,
    CreateMenuComponent,
    CreateSubmenuComponent,
    CreateInformationComponent,
    CreateRifaComponent,
    CreateDetailFormatComponent,
    CreateProductComponent,
    CreateCompanyComponent,
    CreateLikeComponent,
    InformationSubmenuComponent,
    CreateTagComponent,
    CreateUserComponent,
    CreateTicketComponent,
    CreateCategoryComponent,
    CreatePanelcategoryComponent,
    CreateMarcaComponent,
    CreateItemComponent,
    CreateContenidosComponent,
    CreateVideosComponent,
    CreateNoticiasComponent,
    SitiosComponent,
    VotacionesComponent,
    EmailsComponent,
    NoticiasResourcesComponent

  ]
})
export class AppModule { }
