import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {CustomURLEncoder} from '../../urlencoder/component';

@Injectable({
  providedIn: 'root'
})
export class NavbarService {

  menuForm: FormGroup;

  constructor(
    protected httpClient: HttpClient,
    private formBuilder: FormBuilder
  ) {
    this.menuForm = this.formBuilder.group({
      id: null,
      nombre: ['', [Validators.required, Validators.minLength(3)]],
      strIndex: ['', Validators.required],
      color: ['', Validators.required],
      orden: ['', Validators.compose([Validators.required, Validators.pattern('[0-9]+')])],
      tipo: ['', Validators.required],
      link: ['', Validators.required],
      visibleTop: ['', Validators.required],
      visibleOnlyLogin: ['', Validators.required],
      userElement: ['', Validators.required],
      estado: [false]
    });
  }

  /* Menu */
  getNavBars() {
    return this.httpClient.get('/mobilaldiabff/admin/scmenus');
  }

  storeMenu(data: any) {
    const body = new HttpParams({encoder: new CustomURLEncoder()})
      .set('color', data.color)
      .set('orden', data.orden)
      .set('estado', (data.estado) ? '1' : '0')
      .set('link', data.link)
      .set('strIndex', data.strIndex)
      .set('nombre', data.nombre)
      .set('tipo', data.tipo.id)
      .set('userElement', data.userElement.value)
      .set('visibleOnlyLogin', data.visibleOnlyLogin.value)
      .set('visibleTop', data.visibleTop.value);

    return this.httpClient.post<any>(
      '/mobilaldiabff/admin/scmenus',
      body.toString(),
      { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8') }
    );
  }

  editMenu(data: any) {
    this.menuForm.setValue(data);
  }

  updateMenu(data: any) {
    const body = new HttpParams({encoder: new CustomURLEncoder()})
      .set('color', data.color)
      .set('orden', data.orden)
      .set('estado', (data.estado) ? '1' : '0')
      .set('link', data.link)
      .set('strIndex', data.strIndex)
      .set('nombre', data.nombre)
      .set('tipo', data.tipo.id)
      .set('userElement', data.userElement.value)
      .set('visibleOnlyLogin', data.visibleOnlyLogin.value)
      .set('visibleTop', data.visibleTop.value);

    return this.httpClient.put<any>(
      '/mobilaldiabff/admin/scmenus/' + data.id,
      body.toString(),
      { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8') }
    );
  }

  deleteMenu(id: string) {
    return this.httpClient.delete('/mobilaldiabff/admin/scmenus/' + id);
  }

}
