import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SubmenusTypeService {

  constructor(
    protected httpClient: HttpClient
  ) { }

  getSubmenusType() {
    return this.httpClient.get<[]>('/mobilaldiabff/admin/tipo_submenu');
  }
}
